import { useEffect, useRef, useState } from "react";
import { chain } from "lodash";
import Input from "@mui/material/Input";
import getMobileDeviceData from "ismobilejs";
import { useSearchParams } from 'react-router-dom';

import styles from "./Chat.module.css";

import { usePostChatMessageMutation } from "../../app/chat.api";
import { MessageRoles, MessageTypes } from "./Chat.model";
import { useChatHistoryPolling } from "./Chat.hooks";
import { getUrlData } from "../../App.utils";

const IconSend01 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M10.5 13.5 21 3M10.627 13.828l2.628 6.758c.232.596.347.893.514.98a.5.5 0 0 0 .462 0c.167-.086.283-.384.515-.979L21.336 3.7c.21-.537.315-.805.258-.977a.5.5 0 0 0-.316-.316c-.172-.057-.44.048-.978.257L3.413 9.253c-.595.233-.893.349-.98.516a.5.5 0 0 0 0 .461c.087.167.385.283.98.515l6.759 2.628c.12.047.18.07.232.106a.5.5 0 0 1 .116.117c.037.051.06.111.107.232"
    />
  </svg>
);


function useDisablePasting() {
  const [searchParams] = useSearchParams();
  const pastingDisabled =
      !searchParams.has("allow_paste")
      && searchParams.get("preview") !== "true";

  const handlePaste = (e) => {
    if (pastingDisabled) {
      e.preventDefault();
    }
  };

  return handlePaste;
}

export const RespondentInput = () => {
  const [inputText, setInputText] = useState("");
  const [postChatMessageMutation] = usePostChatMessageMutation();
  const { chatMessages, interviewEnded } = useChatHistoryPolling();
  const handlePaste = useDisablePasting();

  const inputRef = useRef();

  useEffect(() => {
    if (interviewEnded) {
      setInputText("");
      inputRef.current.querySelector("textarea").blur();
      return;
    }

    // in case it is embedded in another app it shouldn't steal focus from main app
    const isEmbedded = getUrlData().embedded === 'true'
    if (!isEmbedded) {
      inputRef.current.querySelector("textarea").focus();
    }
  }, [chatMessages, interviewEnded]);

  const [role, type] = chain(chatMessages).last().at(["role", "type"]).value();

  const isEmptyInput = inputText.trim().length === 0;
  const shouldDisableRespondentInput =
    role === MessageRoles.Respondent ||
    type === MessageTypes.TypingProgress ||
    interviewEnded ||
    isEmptyInput;

  const handleInputChange = (e) => {
    const message = e.target.value;
    setInputText(message);
  };

  const handleSendMessage = () => {
    if (shouldDisableRespondentInput) {
      return;
    }
    setInputText("");
    postChatMessageMutation({
      previousFDChatMessageDataId: chain(chatMessages)
        .last()
        .get("fdMessageDataId")
        .value(),
      content: inputText,
    });
  };

  const handleEnterPress = (e) => {
    const isEnterPressed = e.key === "Enter";
    const isMobile = getMobileDeviceData(window.navigator);
    if (!isEnterPressed) {
      // if not Enter pressed do nothing, standard onChange behaviour will be used when any other key is pressed
      return;
    } else if (isMobile.phone || isMobile.tablet) {
      // for mobile devices virtual keyboard will be used and Enter will be handled with default means adding new line to the input; do nothing with it
      return;
    } else if (e.shiftKey) {
      // for desktop use default behaviour in case Shift key is pressed
      return;
    } else {
      // for desktop do not use default behaviour but send message on Enter press
      e.preventDefault();
      handleSendMessage();
    }
  };


  return (
    <Input
      ref={inputRef}
      className={styles.input}
      fullWidth
      multiline
      disableUnderline
      value={inputText}
      disabled={interviewEnded}
      onChange={handleInputChange}
      onKeyPress={handleEnterPress}
      inputProps={{
        autocorrect: "off",
      }}
      endAdornment={
        <button
          type="button"
          tabIndex={shouldDisableRespondentInput ? -1 : 0}
          disabled={shouldDisableRespondentInput}
          className={styles['send-button']}
          onClick={handleSendMessage}
        >
          <IconSend01 /> Send
        </button>
      }
      maxRows={3}
      placeholder="Start typing your message..."
      onPaste={handlePaste}
    />
  );
};
